import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router/router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import api from './api/main'

const app = createApp(App)

// 注册 Element Plus
app.use(ElementPlus)

// 注册路由
app.use(router)

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 注册 API 为全局属性
app.config.globalProperties.$api = api

// 挂载应用
app.mount('#app')


