import axios from 'axios'
import { ElMessage } from 'element-plus'


/**
 * API 基础路径配置
 */
//正式环境
export const BASE_URL = '/kuaida/api/v1/open/openapi/cmidtg0lnoig00e79sk0'
//测试环境
// export const BASE_URL = '/kuaida/api/v1/open/openapi/cv9c8geponag00fskjc0'

/**
 * Token管理 - 允许在运行时设置全局token
 */
let globalToken = ''

// 本地调试环境: Token
// 生产环境: Bearer
// const preFix = 'Token'
const preFix = 'Bearer'

/**
 * 设置全局token，将应用于所有请求
 * @param {string} token - 认证token
 */
export function setGlobalToken(token) {
  globalToken = token
}

/**
 * 获取当前全局token
 * @returns {string} 当前token
 */
export function getGlobalToken() {
  return globalToken
}

/**
 * 创建 axios 实例
 */
const service = axios.create({
  timeout: 10000 // 请求超时时间
})

/**
 * 请求拦截器
 */
service.interceptors.request.use(
  config => {
    // 为所有请求添加Authorization头（如果token存在）
    if (globalToken) {
      console.log('request拦截器添加token')
      config.headers = config.headers || {}
      config.headers['Authorization'] = `${preFix} ${globalToken}`
    }
    
    // 确保请求头不会引起跨域预检请求问题
    config.headers = config.headers || {}
    config.headers['Content-Type'] = 'application/json'
    
    // 在开发环境中启用withCredentials，生产环境不启用以避免跨域问题
    if (process.env.NODE_ENV === 'development') {
      config.withCredentials = true
    } else {
      config.withCredentials = false
    }
    
    return config
  }, 
  error => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
  response => {
    // 处理响应缓存
    if (response.config.method === 'get' && response.config.cache) {
      try {
        // 缓存数据并将当前时间存入，方便之后判断是否过期
        const data = {
          expire: getExpireTime(),
          data: response.data,
          status: 200
        }

        // 存储到 sessionStorage
        sessionStorage.setItem(`${response.config.url}`, JSON.stringify(data))
      } catch (e) {
        // 处理存储限制问题
        if (e.name.toUpperCase().indexOf('QUOTA') >= 0) {
          console.debug('已经超出本地存储限定大小！不缓存数据')
        }
      }
    }
    return response
  }, 
  error => {
    if (axios.isCancel(error)) return Promise.resolve(error.Elmessage)
    
    // 错误处理
    const resp = error.response
    if (!resp) {
      ElMessage.error('网络连接异常，请检查网络')
      return Promise.reject(error)
    }

    // 根据不同状态码处理错误
    if (resp.status === 400 || resp.status === 403) {
      ElMessage.error(resp.data.error)
    } else if (resp.status === 404) {
      ElMessage.error('访问数据不存在')
    } else if (resp.status >= 500) {
      if (resp.status === 504) {
        ElMessage.error('连接服务器超时')
      } else if (resp.status === 502) {
        ElMessage.error('服务器忙,请重试')
      } else {
        ElMessage.error('服务器内部异常')
      }
    }
    return Promise.reject(error)
  }
)

/**
 * 获取当前时间戳
 */
function getExpireTime() {
  return new Date().getTime()
}

export default service