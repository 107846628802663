import { createRouter, createWebHashHistory } from "vue-router"

/**
 * 路由配置
 * 使用懒加载方式引入组件以提高性能
 */

// 视图组件懒加载
const quality = () => import("@/views/quality.vue")
const pkgScan = () => import("@/views/pkgScan.vue")
const dataView = () => import("@/views/dataView.vue")
const workshopView = () => import("@/views/workshopView.vue")
const orderProgress = () => import("@/views/orderProgress.vue")

// 路由配置
const routes = [
  { 
    path: "/", 
    redirect: "/quality" 
  },
  {
    path: "/quality",
    name: "quality",
    component: quality,
    meta: {
      title: "质量检验"
    }
  },
  {
    path: "/middle",
    name: "middle",
    component: quality,
    meta: {
      title: "中间检验"
    }
  },
  {
    path: "/pkgScan",
    name: "pkgScan",
    component: pkgScan,
    meta: {
      title: "工票扫描"
    }
  },
  {
    path: "/dataView",
    name: "dataView",
    component: dataView,
    meta: {
      title: "工人产量"
    }
  },
  {
    path: "/orderProgress",
    name: "orderProgress",
    component: orderProgress,
    meta: {
      title: "工单进度查询"
    }
  },
  {
    path: "/workshopView",
    name: "workshopView",
    component: workshopView,
    meta: { 
      title: "车间产量"
    }
  }
]

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 全局后置钩子
router.afterEach((to) => {
  let title = to.params.title ? to.params.title : ''
  
  if (to.meta && to.meta.title) {
    if (title !== '') {
      title += ' - ' + to.meta.title
    } else {
      title = to.meta.title
    }
  }

  // 设置文档标题
  document.title = title !== '' ? title + ' - 快搭云' : '快搭云'
})

export default router
