import request, { BASE_URL } from '@/utils/request'
// import getObj from './func.js'



/**
 * 主要 API 接口
 */
const main = {
    /**
     * 搜索记录
     * @param {Object} data - 搜索参数
     * @returns {Promise} 请求结果
     */
    searchRecords(data) {
        return request.post(`${BASE_URL}/list?include_base_fields`, data)
    },
    /**
     * 搜索记录
     * @param {Object} data - 搜索参数
     * @returns {Promise} 请求结果
     */
    searchAnalysableRecords(data) {
        return request.post(`${BASE_URL}/analysable/list?include_base_fields`, data)
    },
    /**
     * 创建记录
     * @param {Object} data - 记录数据
     * @returns {Promise} 请求结果
     */
    createRecord(data) {
        return request.post(`${BASE_URL}/create`, data)
    }
}

export default main